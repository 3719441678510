import { Routes } from '@angular/router';

export const APP_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'drivers',
    loadChildren: () => import('./driver/driver.module').then(m => m.DriverModule)
  },
  {
    path: 'mobile-app',
    loadChildren: () => import('./mobile-app/mobile-app.module').then(m => m.MobileAppModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
  }
];
